import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = (props) => {
  const { className, title } = props;
  const cnTextInput = classNames('button', className);

  return (
    <button type="submit" className={cnTextInput}>
      {title}
    </button>
  );
};

Button.defaultProps = {
  className: '',
};

Button.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Button;
