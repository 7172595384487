import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppProvider';

import { Download } from '../shared/svg';

const DownloadApplicants = ({ selectedStages }) => {
  const contextApp = useContext(AppContext);
  const { company } = contextApp;

  return (
    <Link
      className="icon"
      to={`/api/v1/companies/${company.id}/applicants/export?stages=${selectedStages}`}
      target="_blank"
      download
    >
      <Download />
    </Link>
  );
};

DownloadApplicants.propTypes = {
  selectedStages: PropTypes.string.isRequired,
};

export default DownloadApplicants;
