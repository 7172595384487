/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AppContext } from '../../AppProvider';

import { CSV } from '../shared/svg';

const UploadApplicants = ({ isReloadApplicants, setIsReloadApplicants }) => {
  const contextApp = useContext(AppContext);
  const { company } = contextApp;
  const [fileForUpload, setFileForUpload] = useState(null);

  const uploadCsvRequest = async () => {
    try {
      const formData = new FormData();
      formData.append('file', fileForUpload);
      const res = await axios.post(`/api/v1/companies/${company.id}/applicants/import`, formData, { withCredentials: true });
      if (res.status === 200) setIsReloadApplicants(!isReloadApplicants);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('There was an error uploading the file, please try again');
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    uploadCsvRequest();
  };

  const fileInputHandler = (e) => {
    setFileForUpload(e.target.files[0]);
  };

  return (
    <form className="d-flex align-items-center" onSubmit={onFormSubmit}>
      <label htmlFor="upload" role="presentation">
        <input type="file" name="file" id="upload" hidden onChange={(e) => fileInputHandler(e)} />
        <CSV />
      </label>
      <button className="u-ml-1 u-mr-1 u-mt-1 u-mb-1" type="submit">Upload</button>
    </form>
  );
};

UploadApplicants.propTypes = {
  isReloadApplicants: PropTypes.bool.isRequired,
  setIsReloadApplicants: PropTypes.func.isRequired,
};

export default UploadApplicants;
