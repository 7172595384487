import React, { useState, useReducer, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import TextInput from '../shared/TextInput';
import PasswordInput from '../shared/PasswordInput';
import ContactUs from '../shared/ContactUs';
import Button from '../shared/Button';
import routes from '../../api/routes';

import logo from '../../images/contack-logo.png';
import { AppContext } from '../../AppProvider';

const Login = () => {
  const contextApp = useContext(AppContext);
  const { getUserInfo } = contextApp;
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState('');
  const [loginData, setLoginData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      secret: '',
      username: '',
    },
  );

  const loginRequest = async () => {
    try {
      const res = await axios.post(routes.login, loginData, { withCredentials: true });
      if (res.data.status === 200) {
        localStorage.setItem('isAuthrnticated', 'true');
        getUserInfo();
        history.push('/recruitment-dashboard')
      }
    } catch (error) {
      setErrorMessages('Invalid e-mail address or password');
    }
  };

  const changeFieldHandler = (event) => {
    const { name, value } = event.target;
    setLoginData({ [name]: value });
  };

  const loginHandler = (event) => {
    event.preventDefault();
    loginRequest();
  };

  return (
    <div className="d-flex u-h-100">
      <div className="row u-w-100">
        <div className="col-md-5 d-flex flex-column justify-content-center align-items-center u-bg-silver-tree">
          <h1 className="typography typography__heading1 typography--white">LOG IN</h1>
          <p className="typography typography__body1 typography--white">
            to use all of Contack’s features:
          </p>
        </div>
        <div className="offset-md-1 col-md-5 offset-md-1 d-flex flex-column justify-content-around align-items-center">
          <div className="u-height-200" />
          <form
            className="d-flex flex-column justify-content-center align-items-center col-xl-9"
            onSubmit={loginHandler}
          >
            <div className="u-mt-2 u-mb-2 typography typography--persian-red">{errorMessages}</div>
            <TextInput
              type="email"
              className="u-mb-2"
              name="username"
              onChange={changeFieldHandler}
              value={loginData.username}
              required
              placeholder="Email"
            />
            <PasswordInput
              type="password"
              name="secret"
              onChange={changeFieldHandler}
              value={loginData.secret}
              required
              placeholder="Password"
            />
            <Button className="u-mb-6" title="Log in" />
          </form>
          <div className="d-flex flex-column justify-content-end align-items-center">
            <ContactUs />
            <img width="120" src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
