/* eslint-disable */
import React from 'react';
import SelectItem from './select-item';

const skipIndex = 2;

const SelectList = ({
  value = [],
  onChange,
  ItemRenderer,
  options,
  focusIndex,
  onClick,
}) => {
  const handleSelectionChanged = (option, checked) => {
    onChange(
      checked
        ? [...value, option]
        : value.filter((o) => o.value !== option.value),
    );
  };

  return (
    <ul className="select-list">
      {options.map((o, i) => {
        const tabIndex = i + skipIndex;
        return (
          <li key={o.hasOwnProperty('key') ? o.key : i}>
            <SelectItem
              focused={focusIndex === tabIndex}
              tabIndex={tabIndex}
              option={o}
              onSelectionChanged={(c) => handleSelectionChanged(o, c)}
              checked={!!value.find((s) => s.value === o.value)}
              onClick={(e) => onClick(e, tabIndex)}
              itemRenderer={ItemRenderer}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default SelectList;
