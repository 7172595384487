import React, {
  useContext, useState, useEffect, useCallback,
} from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import {
  MenuItem,
  DropdownButton,
} from '@trendmicro/react-dropdown';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../AppProvider';
import { AppLicantModalContext } from '../../ApplicantModalProvider';
import { formatContackELTV, sortAsc, sortDesc } from '../../utils';
import { monthDayYearFormat } from '../../utils/date';
import { StageList } from '../../constants';

import { Arrow } from '../shared/svg';

Modal.setAppElement('#modal-root');

const ApplicantModal = () => {
  const contextApp = useContext(AppContext);
  const contextModal = useContext(AppLicantModalContext);
  const { openModal, setOpenModal, applicantBaseId } = contextModal;
  const { company } = contextApp;

  const [applicantInfo, setApplicantInfo] = useState({ applicant: {}, availableStages: [] });
  const {
    applicantId,
    eltv,
    stage,
    appliedDate,
    appliedRole,
    backgroundCheck,
    comments,
    experience = [],
  } = applicantInfo.applicant;

  const { availableStages } = applicantInfo;

  const [selectedSort, setSelectedSort] = useState({ value: '', label: '' });

  const [selectedStage, setSelectedStage] = useState('');

  const hanldeCloseModal = () => setOpenModal(false);

  const getApplicantInfo = useCallback(() => {
    axios.get(`/api/v1/companies/${company.id}/applicants/${applicantBaseId}`)
      .then((res) => setApplicantInfo(res.data))
      // eslint-disable-next-line no-console
      .catch((error) => console.log('error', error));
  }, [company, applicantBaseId]);

  const updateApplicantInfo = useCallback(() => {
    axios.patch(`/api/v1/companies/${company.id}/applicants/${applicantBaseId}`,
      { stage: selectedStage, comments: '' })
      .then((res) => setApplicantInfo(res.data))
      // eslint-disable-next-line no-console
      .catch((error) => console.log('error', error));
  }, [company, applicantBaseId, selectedStage]);

  useEffect(() => {
    if (!!applicantBaseId && openModal) getApplicantInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicantInfo]);

  useEffect(() => {
    if (!!applicantBaseId && openModal && selectedStage) updateApplicantInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateApplicantInfo]);

  const sortExperienceByDate = (val) => {
    if (selectedSort.value === 'asc') return sortAsc(val, 'fromDate');
    if (selectedSort.value === 'desc') return sortDesc(val, 'fromDate');

    return val;
  };

  const getStageOptions = () => availableStages.map((item) => ({
    value: item,
    label: StageList[item],
  }));

  const sortByOptions = [
    { value: 'desc', label: 'Oldest' },
    { value: 'asc', label: 'Newest' },
  ];

  const cnRoleApplied = classNames('typography typography--dim-gray u-bg-canary u-ml-4',
    { 'role-applied': !!appliedRole });

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={hanldeCloseModal}
      contentLabel="Example Modal"
      className="modal"
    >
      <div className="modal__container u-bg-white">
        <div
          role="presentation"
          className="modal__close typography typography--zambezi"
          onClick={hanldeCloseModal}
        >
          &times;
        </div>
        <div className="modal-content d-flex flex-column">
          <div className="modal-content__header u-mb-3 d-flex justify-content-between u-bg-medium-sea-green">
            <div className="d-flex flex-column">
              <div className="caption typography typography--white">Experience</div>
              <div className="sort-by typography typography--white u-mt-2">
                Sort by:&nbsp;
                <DropdownButton
                  btnSize="sm"
                  title="Relevance"
                  onSelect={(eventKey) => {
                    setSelectedSort(eventKey);
                  }}
                >
                  {sortByOptions.map((elem) => (
                    <MenuItem key={elem.value} style={{ width: '97px' }} eventKey={elem.value}>{elem.label}</MenuItem>
                  ))}
                </DropdownButton>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="candidate-name typography typography--white">{applicantId}</div>
              <div className="candidate-status typography typography--white u-mt-1">
                Status:&nbsp;
                <span className="typography typography--dodger-blue">
                  {StageList[stage]}
                </span>
              </div>
              <DropdownButton
                className="u-mt-2"
                style={{ width: '137px' }}
                btnSize="sm"
                title="Available stages"
                onSelect={(eventKey) => {
                  setSelectedStage(eventKey);
                }}
              >
                {getStageOptions().map((elem) => (
                  <MenuItem key={elem.value} eventKey={elem.value}>{elem.label}</MenuItem>
                ))}
              </DropdownButton>
            </div>
          </div>
          <div className="modal-content__body d-flex justify-content-between">
            <div className="experience d-flex flex-column">
              {sortExperienceByDate(experience).map((elem) => (
                <div key={uuidv4('3e8bbc3e-bfb6-490d-966c-7bc5a192203e')} className="u-mb-1">
                  <div className="typography typography--white u-mt-1 experience__type">
                    {elem.type}
                    :
                  </div>
                  <div className="typography typography--white experience__details">
                    <p>{elem.details}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="details d-flex flex-column">
              <div className="d-flex align-items-center u-mb-1">
                <div className="typography typography--dim-gray">Contack ELTV:&nbsp;</div>
                <span className="u-medium typography typography--medium-sea-green u-ml-4">
                  {formatContackELTV(eltv)}
                </span>
              </div>
              <div className="d-flex align-items-center u-mb-1">
                <div className="typography typography--dim-gray">Role Applied:&nbsp;</div>
                <span className={cnRoleApplied}>
                  {appliedRole}
                </span>
              </div>
              <div className="d-flex align-items-center u-mb-1">
                <div className="typography typography--dim-gray">Date Applied:&nbsp;</div>
                <span className="u-medium typography typography--medium-sea-green u-ml-4">
                  {monthDayYearFormat(appliedDate)}
                </span>
              </div>
              <div className="d-flex align-items-center u-mb-1">
                <div className="typography typography--dim-gray">Background Check:&nbsp;</div>
                <div className="row-value">
                  {backgroundCheck && <Arrow />}
                </div>
              </div>
              <div className="d-flex u-mb-1">
                <div className="typography typography--dim-gray">Comments:&nbsp;</div>
                <textarea className="u-ml-1" readOnly name="comments" value={comments || ''} cols="30" rows="10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicantModal;
