/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Eye } from '../svg';

const PasswordInput = (props) => {
  const { type, className } = props;
  const [changedType, setChangedType] = useState(type);

  const cnPasswordInput = classNames('input', className);

  const showPasswordHandler = () => {
    // eslint-disable-next-line no-unused-expressions
    changedType === 'password' ? setChangedType('text') : setChangedType('password');
  };

  return (
    <div className="d-flex field-group u-w-100 u-mb-2">
      <input
        {...props}
        type={changedType}
        className={cnPasswordInput}
      />
      <div
        role="presentation"
        className="field-group__icon field-group__icon--large"
        onClick={showPasswordHandler}
      >
        <Eye />
      </div>
    </div>
  );
};

PasswordInput.defaultProps = {
  type: 'password',
  className: '',
};

PasswordInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

export default PasswordInput;
