import { Column } from '../../constants';

const {
  CONTACK_ELTV, APPLICANT_ID, ROLE_APPLIED, DATE_APPLIED, TEST_RESULTS,
} = Column;

const tabList = [
  {
    tabIndex: 0,
    label: 'All',
    stageName: '',
    tableHeaders: [CONTACK_ELTV, APPLICANT_ID, ROLE_APPLIED, DATE_APPLIED, TEST_RESULTS],
  },
  {
    tabIndex: 1,
    label: 'Pre-Test',
    stageName: 'PRE_TEST',
    tableHeaders: [CONTACK_ELTV, ROLE_APPLIED, DATE_APPLIED],
  },
  {
    tabIndex: 2,
    label: 'Test Assessment',
    stageName: 'TEST_ASSESSMENT',
    tableHeaders: [APPLICANT_ID, ROLE_APPLIED, DATE_APPLIED, TEST_RESULTS],
  },
  {
    tabIndex: 3,
    label: 'Interview',
    stageName: 'INTERVIEW',
    tableHeaders: [CONTACK_ELTV, APPLICANT_ID, ROLE_APPLIED, DATE_APPLIED, TEST_RESULTS],
  },
  {
    tabIndex: 4,
    label: 'Offer in Progress',
    stageName: 'OFFER_IN_PROGRESS',
    tableHeaders: [CONTACK_ELTV, APPLICANT_ID, ROLE_APPLIED, DATE_APPLIED, TEST_RESULTS],
  },
];

export default tabList;
