import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StageTab = ({ tabList, activeStageTab, setActiveStageTab }) => {
  const onClickTabItem = (val) => {
    setActiveStageTab(val);
  };

  return (
    <ul className="u-mb-4 state-tab">
      {
        tabList.map((item) => {
          const cnStageTab = classNames('state-tab__item', {
            'state-tab__item--active': item.tabIndex === activeStageTab.tabIndex,
          });

          return (
            <li
              key={item.tabIndex}
              className={cnStageTab}
              role="presentation"
              onClick={() => onClickTabItem(item)}
            >
              {item.label}
            </li>
          );
        })
      }
    </ul>
  );
};

StageTab.propTypes = {
  setActiveStageTab: PropTypes.func.isRequired,
  activeStageTab: PropTypes.shape({
    tabIndex: PropTypes.number,
    label: PropTypes.string,
    stageName: PropTypes.string,
  }).isRequired,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      tabIndex: PropTypes.number,
      label: PropTypes.string,
      stageName: PropTypes.string,
    }),
  ).isRequired,
};

export default StageTab;
