/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRanger } from 'react-ranger';

const RangeSlider = ({ filteredApplicants, setRangedApplicants }) => {
  const getRankData = () => filteredApplicants.map((elem) => elem.rank);
  const minValueRange = getRankData()[getRankData().length - 1];
  const maxValueRange = getRankData()[0];

  const [values, setValues] = useState([1, 100]);

  const { getTrackProps, handles } = useRanger({
    values,
    onChange: setValues,
    min: minValueRange,
    max: maxValueRange,
    stepSize: 1,
  });

  const setApplicants = useCallback(() => {
    setRangedApplicants(filteredApplicants.filter((elem) => (
      elem.rank >= values[0] && elem.rank <= values[1])));
  }, [filteredApplicants, setRangedApplicants, values]);

  useEffect(() => {
    setApplicants();
  }, [setApplicants]);

  return (
    <div className="range-slider">
      <div className="d-flex u-mb-1 justify-content-between range-slider__values">
        <span className="ml-n2">
          #
          {minValueRange}
        </span>
        <span className="mr-n2">
          #
          {maxValueRange}
        </span>
      </div>
      <div className="range-slider__scale">
        <div className="separator separator--long" />
        <div className="separator" />
        <div className="separator" />
        <div className="separator" />
        <div className="separator" />
        <div className="separator separator--long" />
        <div className="separator" />
        <div className="separator" />
        <div className="separator" />
        <div className="separator" />
        <div className="separator separator--long" />
      </div>
      <div
        className="range-slider__line"
        {...getTrackProps()}
      >
        {handles.map(({ getHandleProps }) => (
          <div
            className="range-slider__button"
            {...getHandleProps()}
          />
        ))}
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  filteredApplicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.string.isRequired,
      contackEltv: PropTypes.number,
      rank: PropTypes.number,
      stage: PropTypes.string,
    }),
  ).isRequired,
  setRangedApplicants: PropTypes.func.isRequired,
};

export default RangeSlider;
