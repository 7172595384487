import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { AllHtmlEntities } from 'html-entities';

const entities = new AllHtmlEntities();

const NavItem = ({
  navId, activeTab, label, iconCode, path, onClickTabItem,
}) => {
  const cnNavLink = classNames('nav-link', { active: navId === activeTab });
  return (
    <li
      className={cnNavLink}
      role="presentation"
      onClick={() => onClickTabItem(navId)}
    >
      <Link to={path}>
        <span className="nav-link-icon">{entities.decode(iconCode)}</span>
        {label}
      </Link>
      <div className="up-corner"><div className="up-corner__inner" /></div>
      <div className="down-corner"><div className="down-corner__inner" /></div>
    </li>
  );
};

NavItem.propTypes = {
  navId: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  iconCode: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClickTabItem: PropTypes.func.isRequired,
};

export default NavItem;
