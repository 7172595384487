/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import DownloadApplicants from '../../DownloadApplicants';
import UploadApplicants from '../../UploadApplicants';
import { Search } from '../svg';

const ActionPanel = ({
  filteredApplicants,
  activeStageTab,
  setSearchedApplicants,
  isReloadApplicants,
  setIsReloadApplicants,
}) => {
  const [searchByApplicantId, setSearchByApplicantId] = useState('');

  const searchHandler = (e) => {
    setSearchByApplicantId(e.target.value);
  };

  const setSearchValue = useCallback(() => {
    const filteredData = filteredApplicants.filter((item) => (
      searchByApplicantId === '' ? filteredApplicants : item.applicantId.includes(searchByApplicantId)
    ));
    setSearchedApplicants(filteredData);
  }, [searchByApplicantId]);

  useEffect(() => {
    setSearchValue();
  }, [setSearchValue]);

  useEffect(() => {
    setSearchedApplicants(filteredApplicants);
  }, [filteredApplicants]);

  return (
    <div className="panel panel--large-padding u-mb-3">
      <div className="d-flex align-items-center">
        <div className="field-group field-group--search">
          <input
            type="search"
            className="field-group__input"
            name="search"
            placeholder="search"
            onChange={(e) => searchHandler(e)}
          />
          <div className="field-group__icon">
            <Search />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <DownloadApplicants selectedStages={activeStageTab.stageName} />
        <div className="u-ml-2">
          <UploadApplicants
            isReloadApplicants={isReloadApplicants}
            setIsReloadApplicants={setIsReloadApplicants}
          />
        </div>
      </div>
    </div>
  );
};

ActionPanel.propTypes = {
  filteredApplicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.string.isRequired,
      contackEltv: PropTypes.number,
      rank: PropTypes.number,
      stage: PropTypes.string,
    }),
  ).isRequired,
  activeStageTab: PropTypes.shape({
    tabIndex: PropTypes.number,
    label: PropTypes.string,
    stageName: PropTypes.string,
  }).isRequired,
  setSearchedApplicants: PropTypes.func.isRequired,
  isReloadApplicants: PropTypes.bool.isRequired,
  setIsReloadApplicants: PropTypes.func.isRequired,
};

export default ActionPanel;
