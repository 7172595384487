/* eslint-disable import/prefer-default-export */
export const Column = {
  CONTACK_ELTV: {
    name: 'Contack ELTV',
    property: 'contackEltv',
  },
  APPLICANT_ID: {
    name: 'Applicant ID',
    property: 'applicantId',
  },
  ROLE_APPLIED: {
    name: 'Role Applied',
    property: 'roleApplied',
  },
  DATE_APPLIED: {
    name: 'Date Applied',
    property: 'dateApplied',
  },
  TEST_RESULTS: {
    name: 'Test Results',
    property: 'testResults',
  },
  DATE_OFFER_ACCEPTED: {
    name: 'Date Offer Accepted',
    property: 'offerAcceptedDate',
  },
  DATE_START: {
    name: 'Date Start',
    property: 'startDate',
  },
};

export const StageList = {
  PRE_TEST: 'Pre-Test',
  TEST_ASSESSMENT: 'Test Assessment',
  INTERVIEW: 'Interview',
  OFFER_IN_PROGRESS: 'Offer in Progress',
  ON_BOARD: 'On Board',
  REJECTED: 'Rejected',
};
