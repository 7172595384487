import React, { useContext } from 'react';
import { AppContext } from '../../AppProvider';
import avatar from '../../images/user-icon-default.png';

const UserInfo = () => {
  const context = useContext(AppContext);
  const { company: { name }, email } = context;

  return (
    <div className="user-info">
      <img className="user-avatar" src={avatar} alt="Avatar" />
      <div className="user-company-name">{name}</div>
      <div className="user-email">{email}</div>
    </div>
  );
};

export default UserInfo;
