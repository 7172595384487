import React from 'react';
import PropTypes from 'prop-types';

const ArrowSort = ({ isActive }) => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 0L8 3.852L6.809 5L4 2.3L1.191 5L0 3.852L4 0Z" fill={isActive ? '#53BB8E' : '#FFFFFF'} />
  </svg>
);

ArrowSort.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default ArrowSort;
