import { Column } from '../../constants';

const {
  CONTACK_ELTV, APPLICANT_ID, ROLE_APPLIED, DATE_OFFER_ACCEPTED, DATE_START,
} = Column;

const tabList = [
  {
    tabIndex: 0,
    label: 'All',
    stageName: '',
    tableHeaders: [CONTACK_ELTV, APPLICANT_ID, ROLE_APPLIED, DATE_OFFER_ACCEPTED, DATE_START],
  },
];

export default tabList;
