/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Filter } from '../../svg';

const Dropdown = ({
  children,
  contentComponent: ContentComponent,
  contentProps,
  shouldToggleOnHover,
  labelledBy,
  onMenuToggle,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const wrapper = useRef();

  useEffect(() => {
    if (onMenuToggle) onMenuToggle(expanded);
  }, [expanded]);

  const handleKeyDown = (e) => {
    switch (e.which) {
      case 27: // Escape
      case 38: // Up Arrow
        setExpanded(false);
        wrapper.current.focus();
        break;
      case 32: // Space
      case 13: // Enter Key
      case 40: // Down Arrow
        setExpanded(true);
        break;
      default:
        return;
    }
    e.preventDefault();
  };

  const handleHover = (iexpanded) => {
    if (shouldToggleOnHover) setExpanded(iexpanded);
  };

  const handleFocus = () => !hasFocus && setHasFocus(true);

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setHasFocus(false);
      setExpanded(false);
    }
  };

  const handleMouseEnter = () => handleHover(true);

  const handleMouseLeave = () => handleHover(false);

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div
      tabIndex={0}
      role="button"
      className="dropdown-container"
      aria-labelledby={labelledBy}
      aria-expanded={expanded}
      ref={wrapper}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div role="presentation" className="d-flex multi-select-button" onClick={toggleExpanded}>
        <Filter />
        <span className="u-ml-1">Filter</span>
      </div>
      <div
        className="dropdown-heading"
      >
        <div className="dropdown-heading-value">{children}</div>
      </div>
      {expanded && (
        <div className="dropdown-content">
          <div className="panel-content">
            <ContentComponent {...contentProps} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
