/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export default (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="25.299" height="19.215" viewBox="0 0 25.299 19.215">
    <path
      id="Path_116"
      dataname="Path 116"
      d="M538.5,251l8.978-7.291,5.551,4.575,6.629-12.358"
      transform="translate(-536.39 -233.895)"
      fill="none"
      stroke="#707070"
      strokeLinecap="round"
      strokeWidth="3"
    />
  </svg>
);
