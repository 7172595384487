import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Line, Chart } from 'react-chartjs-2';
import RangeSlider from './RangeSlider.component';

import { formatContackELTVForGraph } from '../../utils';

import { AppLicantModalContext } from '../../ApplicantModalProvider';

const Charts = ({
  filteredApplicants, rangedApplicants, setRangedApplicants, selectedFilterValues,
}) => {
  const lineRef = useRef(null);
  const context = useContext(AppLicantModalContext);
  const { setOpenModal, setApplicantBaseId } = context;

  const getContackEltvData = () => rangedApplicants.map((elem) => elem.contackEltv);
  const getRankData = () => rangedApplicants.map((elem) => elem.rank);

  const getFilterColor = () => (selectedFilterValues.length === 1 ? selectedFilterValues[0].colorTheme : '');

  const setColor = () => {
    switch (getFilterColor()) {
      case 'blue':
        return '#27B0EB';
      case 'darkGreen':
        return '#2F7D5B';
      case 'red':
        return '#EE3E5D';
      default:
        return '#44B584';
    }
  };

  Chart.Tooltip.positioners.custom = (elements, position) => {
    if (!elements.length) {
      return false;
    }
    let offset = 0;
    // adjust the offset left or right depending on the event position
    if (lineRef.current.chartInstance.chart.width / 2 > position.x) {
      offset = 0;
    } else {
      offset = -160;
    }
    return {
      x: position.x + offset,
      y: position.y,
    };
  };

  const data = {
    type: 'line',
    labels: getRankData(),
    datasets: [{
      label: 'Dataset 1',
      fill: 'origin',
      backgroundColor: setColor(),
      borderColor: setColor(),
      borderWidth: 2,
      pointBackgroundColor: '#5C5C5C',
      pointBorderColor: setColor(),
      data: getContackEltvData(),
      order: 1,
    }],
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 16,
        left: 30,
        right: 30,
        bottom: 20,
      },
    },
    scales: {
      xAxes: [{
        gridLines: {
          drawTicks: false,
          drawOnChartArea: false,
          color: '#FFFFFF',
        },
        ticks: {
          display: true,
        },
      }],
      yAxes: [{
        gridLines: {
          drawTicks: true,
          drawOnChartArea: false,
          color: '#FFFFFF',
        },
        ticks: {
          display: true,
          padding: 16,
          fontSize: 16,
          lineHeight: '18px',
          fontColor: '#FFFFFF',
          callback: (val) => formatContackELTVForGraph(val),
        },
      }],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      position: 'custom',
      custom: (tooltipModel) => {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        const getBody = (bodyItem) => bodyItem.lines;

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '<thead>';

          titleLines.forEach(() => {
            innerHtml += '<tr><th></th></tr>';
          });
          innerHtml += '</thead><tbody>';

          bodyLines.forEach(() => {
            const labelData = tooltipModel.dataPoints[0].label;
            const applicantIdList = rangedApplicants.map((elem) => elem.applicantId);
            const applicantIdElem = applicantIdList[tooltipModel.dataPoints[0].index];

            const tRow = `<tr>
                <td class="tooltip-row" style="background-color: ${setColor()};">
                  <span class="tooltip-row__value">${applicantIdElem}</span>
                  <div class="tooltip-row__label-wrapper">
                    <span class="tooltip-label" style="color: ${setColor()};">#${labelData}</span>
                  </div>
                </td>
              <tr>`;
            innerHtml += tRow;
          });
          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }

        const position = lineRef.current.chartInstance.chart.canvas.getBoundingClientRect();
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
        tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
        tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
        tooltipEl.style.pointerEvents = 'none';
      },
    },
    onHover: (e) => {
      const point = lineRef.current.chartInstance.getElementAtEvent(e);
      if (point.length) e.target.style.cursor = 'pointer';
      else e.target.style.cursor = 'default';
    },
    onClick: (_, it) => {
      if (it.length > 0) {
        setOpenModal(true);
        // eslint-disable-next-line no-underscore-dangle
        setApplicantBaseId(rangedApplicants[it[0]._index].id);
      }
    },
  };

  return (
    <div>
      <RangeSlider
        filteredApplicants={filteredApplicants}
        setRangedApplicants={setRangedApplicants}
      />
      <div className="chart-title">Employee lifetime value</div>
      <Line ref={lineRef} data={data} height={510} width={1200} options={options} />
    </div>
  );
};

Charts.propTypes = {
  filteredApplicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.string.isRequired,
      contackEltv: PropTypes.number,
      rank: PropTypes.number,
      stage: PropTypes.string,
    }),
  ).isRequired,
  rangedApplicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.string.isRequired,
      contackEltv: PropTypes.number,
      rank: PropTypes.number,
      stage: PropTypes.string,
    }),
  ).isRequired,
  setRangedApplicants: PropTypes.func.isRequired,
  selectedFilterValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      label: PropTypes.string,
      colorTheme: PropTypes.string,
    }),
  ).isRequired,
};

export default Charts;
