import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import routes from './api/routes';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: '',
      company: {},
      email: '',
    },
  );

  const getUserInfo = async () => {
    try {
      const res = await axios.get(routes.userInfo);
      setUserInfo(res.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <AppContext.Provider value={{ ...userInfo, getUserInfo }}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
