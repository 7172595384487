import React, {
  useState, useEffect, useReducer, useCallback,
} from 'react';
import axios from 'axios';
import ActionPanel from './ActionPanel.component';
import Charts from './Charts.component';

import { sortDesc } from '../../utils';
import routes from '../../api/routes';

const ContackRankingPage = () => {
  const [isReloadApplicants, setIsReloadApplicants] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState([]);
  const [applicants, setApplicants] = useReducer(
    (_, newState) => ([...newState]),
    [],
  );

  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [rangedApplicants, setRangedApplicants] = useState([]);

  const normalizeApplicants = (val) => sortDesc(val.filter((elem) => elem.rank), 'rank');

  const getApplicants = useCallback(() => {
    axios.get(routes.contackRanking)
      .then((res) => setApplicants(normalizeApplicants(res.data.applicants)))
      // eslint-disable-next-line no-console
      .catch((error) => console.log('error', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloadApplicants]);

  const filterApplicantsByStage = useCallback((stage) => (
    selectedFilterValues.flatMap((elem) => elem.value).includes(stage)
  ), [selectedFilterValues]);

  useEffect(() => {
    getApplicants();
  }, [getApplicants]);

  useEffect(() => {
    setFilteredApplicants(
      selectedFilterValues.length
        ? applicants.filter((item) => filterApplicantsByStage(item.stage)) : applicants,
    );
  }, [applicants, selectedFilterValues, setFilteredApplicants, filterApplicantsByStage]);

  useEffect(() => {
    setRangedApplicants(filteredApplicants);
  }, [filteredApplicants]);

  return (
    <main className="layout__r-side layout-container">
      <h1 className="typography typography__heading2 typography--dim-gray u-mb-2">
        Contack Ranking
      </h1>
      <ActionPanel
        selectedFilterValues={selectedFilterValues}
        setSelectedFilterValues={setSelectedFilterValues}
        isReloadApplicants={isReloadApplicants}
        setIsReloadApplicants={setIsReloadApplicants}
      />
      <Charts
        filteredApplicants={filteredApplicants}
        rangedApplicants={rangedApplicants}
        selectedFilterValues={selectedFilterValues}
        setRangedApplicants={setRangedApplicants}
      />
    </main>
  );
};

export default ContackRankingPage;
