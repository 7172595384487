import React, { useState } from 'react';
import NavItem from './NavItem.component';
import navList from '../../config/navList';

const Navigation = () => {
  const [activeTab, setActiveTab] = useState(0);

  const onClickTabItem = (activeNavId) => {
    setActiveTab(activeNavId);
  };

  return (
    <nav>
      <ul className="navigation">
        {
            navList.map((item) => {
              const {
                navId, label, iconCode, path,
              } = item;

              return (
                <NavItem
                  key={navId}
                  activeTab={activeTab}
                  navId={navId}
                  label={label}
                  iconCode={iconCode}
                  path={path}
                  onClickTabItem={onClickTabItem}
                />
              );
            })
          }
      </ul>
    </nav>
  );
};

export default Navigation;
