import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../shared/MultiSelect';
import UploadApplicants from '../UploadApplicants';
import DownloadApplicants from '../DownloadApplicants';
import { Graph } from '../shared/svg';

const options = [
  { label: 'Onboard Candidates', value: 'ON_BOARD', colorTheme: 'darkGreen' },
  { label: 'Rejected Candidates', value: 'REJECTED', colorTheme: 'red' },
  { label: 'Current Candidates', value: ['TEST_ASSESSMENT', 'INTERVIEW', 'OFFER_IN_PROGRESS'], colorTheme: 'blue' },
];

const ActionPanel = ({
  selectedFilterValues, setSelectedFilterValues, setIsReloadApplicants, isReloadApplicants,
}) => {
  const getSelectedStages = () => (!selectedFilterValues.length
    ? options.flatMap((elem) => elem.value).join(',') : selectedFilterValues.flatMap((elem) => elem.value).join(','));

  return (
    <div className="panel panel--large-padding u-mb-3">
      <div className="d-flex align-items-center u-w-100">
        <Graph />
        <p className="u-mr-1 u-ml-1 u-medium">Graph View</p>
        <div className="u-ml-3 d-flex align-items-center">
          <MultiSelect
            options={options}
            value={selectedFilterValues}
            onChange={setSelectedFilterValues}
            labelledBy="Select"
          />
        </div>
        <div className="d-flex align-items-center ml-auto">
          <DownloadApplicants selectedStages={getSelectedStages()} />
          <div className="u-ml-2">
            <UploadApplicants
              isReloadApplicants={isReloadApplicants}
              setIsReloadApplicants={setIsReloadApplicants}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ActionPanel.propTypes = {
  selectedFilterValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedFilterValues: PropTypes.func.isRequired,
  isReloadApplicants: PropTypes.bool.isRequired,
  setIsReloadApplicants: PropTypes.func.isRequired,
};

export default ActionPanel;
