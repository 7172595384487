/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export default (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23">
    <g id="Group_187" dataname="Group 187" transform="translate(-822.5 -293.5)">
      <g id="Group_186" dataname="Group 186" transform="translate(531 853.5) rotate(-90)">
        <line id="Line_27" className="line" dataname="Line 27" x2="20" transform="translate(538.5 295.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="3" />
        <line id="Line_28" className="line" dataname="Line 28" x2="19.5" transform="translate(538.5 302.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="3" />
        <line id="Line_29" className="line" dataname="Line 29" x2="19.5" transform="translate(538.5 309.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="3" />
      </g>
      <line id="Line_33" className="line" dataname="Line 33" x2="6" transform="translate(823.5 299.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2" />
      <line id="Line_34" className="line" dataname="Line 34" x2="6" transform="translate(830.5 306.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2" />
      <line id="Line_35" className="line" dataname="Line 35" x2="6" transform="translate(837.5 302.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2" />
    </g>
  </svg>
);
