import React from 'react';

const TermsAndConditions = () => (
  <div className="d-flex lustify-content-center">
    <input type="checkbox" required />
    <p className="u-ml-1 typography typography--dim-gray">
      By checking this box I confirm I have read the&nbsp;
      <a
        className="link typography typography--medium-sea-green"
        href="https://contack.io/terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions
      </a>
    </p>
  </div>
);

export default TermsAndConditions;
