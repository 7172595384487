import React, {
  useContext, useState, useEffect, useReducer, useCallback,
} from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ActionPanel from '../shared/ActionPanel';
import StageTab from '../StageTab';

import tabList from './tabList';
import { Column } from '../../constants';
import { formatContackELTV, sortAsc, sortDesc } from '../../utils';
import { shortdateFormat } from '../../utils/date';
import routes from '../../api/routes';
import { AppLicantModalContext } from '../../ApplicantModalProvider';
import { ArrowSort } from '../shared/svg';

const OnboardCandidatesPage = () => {
  const modalContext = useContext(AppLicantModalContext);
  const { setOpenModal, setApplicantBaseId } = modalContext;
  const {
    CONTACK_ELTV,
    APPLICANT_ID,
    ROLE_APPLIED,
    DATE_OFFER_ACCEPTED,
    DATE_START,
  } = Column;

  const [isReloadApplicants, setIsReloadApplicants] = useState(false);
  const [activeStageTab, setActiveStageTab] = useState({
    tabIndex: 0,
    label: 'All',
    stageName: 'ON_BOARD',
    tableHeaders: [
      {
        name: CONTACK_ELTV.name,
        property: CONTACK_ELTV.property,
      },
      {
        name: APPLICANT_ID.name,
        property: APPLICANT_ID.property,
      },
      {
        name: ROLE_APPLIED.name,
        property: ROLE_APPLIED.property,
      },
      {
        name: DATE_OFFER_ACCEPTED.name,
        property: DATE_OFFER_ACCEPTED.property,
      },
      {
        name: DATE_START.name,
        property: DATE_START.property,
      },
    ],
  });
  const [activeSortButton, setActiveSortButton] = useState({ direction: '', property: '' });

  const [applicants, setApplicants] = useReducer(
    (state, newState) => ([...state, ...newState]),
    [],
  );

  const [filteredApplicants, setFilteredApplicants] = useState([]);

  const [searchedApplicants, setSearchedApplicants] = useState([]);

  const isActiveSortButton = (direction, property) => {
    const obj = { direction, property };
    return JSON.stringify(obj) === JSON.stringify(activeSortButton);
  };

  const sortApplicantsHandler = (e, sortProperty) => {
    const { direction } = e.currentTarget.dataset;
    setActiveSortButton(({ direction, property: sortProperty }));

    if (direction === 'asc') {
      setFilteredApplicants(sortAsc(filteredApplicants, sortProperty));
    } else if (direction === 'desc') {
      setFilteredApplicants(sortDesc(filteredApplicants, sortProperty));
    }
  };

  const getApplicants = useCallback(() => {
    axios.get(routes.onboardCandidates)
      .then((res) => setApplicants(res.data.applicants))
      // eslint-disable-next-line no-console
      .catch((error) => console.log('error', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloadApplicants]);

  const modalApplicantHandler = (id) => {
    setOpenModal(true);
    setApplicantBaseId(id);
  };

  useEffect(() => {
    getApplicants();
  }, [getApplicants]);

  useEffect(() => {
    setFilteredApplicants(applicants);
  }, [applicants]);

  return (
    <main className="layout__r-side layout-container">
      <h1 className="typography typography__heading2 typography--dim-gray u-mb-2">
        Onboard Candidates
      </h1>
      <ActionPanel
        activeStageTab={activeStageTab}
        filteredApplicants={filteredApplicants}
        setSearchedApplicants={setSearchedApplicants}
        isReloadApplicants={isReloadApplicants}
        setIsReloadApplicants={setIsReloadApplicants}
      />
      <div className="panel flex-column">
        <StageTab
          tabList={tabList}
          activeStageTab={activeStageTab}
          setActiveStageTab={(val) => setActiveStageTab(val)}
        />
        <table className="table">
          <thead className="table__head">
            <tr>
              {
              activeStageTab.tableHeaders.map((item) => (
                <th
                  key={uuidv4('ae60c31e-dd87-4591-966b-28e8d82fcf42')}
                  className="t-cell typography typography--white typography__body2 u-bg-dim-gray"
                >
                  <div className="d-flex align-items-center t-cell__inner">
                    <div>{item.name}</div>
                    <div className="u-ml-2 d-flex flex-column">
                      <button
                        type="button"
                        className="arrow"
                        data-direction="asc"
                        onClick={(e) => sortApplicantsHandler(e, item.property)}
                      >
                        <ArrowSort isActive={isActiveSortButton('asc', item.property)} />
                      </button>
                      <button
                        type="button"
                        className="arrow arrow__down"
                        data-direction="desc"
                        onClick={(e) => sortApplicantsHandler(e, item.property)}
                      >
                        <ArrowSort isActive={isActiveSortButton('desc', item.property)} />
                      </button>
                    </div>
                  </div>
                </th>
              ))
            }
            </tr>
          </thead>
          <tbody className="table__body">
            {
              searchedApplicants.map((item) => (
                <tr
                  key={uuidv4('944c8c0b-73c6-4272-918f-a9464bdcabd5')}
                  className="t-row"
                  onClick={() => modalApplicantHandler(item.id)}
                >
                  <td className="t-cell typography typography__body2 typography--centered">{formatContackELTV(item.contackEltv)}</td>
                  <td className="t-cell typography typography__body2 typography--centered">{item.applicantId}</td>
                  <td className="t-cell typography typography__body2 typography--centered">{item.roleApplied}</td>
                  <td className="t-cell typography typography__body2 typography--centered">{shortdateFormat(item.offerAcceptedDate)}</td>
                  <td className="t-cell typography typography__body2 typography--centered">{shortdateFormat(item.startDate)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default OnboardCandidatesPage;
