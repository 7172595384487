const routes = {
  signUpCompany: '/api/v1/auth/sign-up/company',
  signUpCompanyUser: '/api/v1/auth/sign-up/company/user',
  login: '/api/v1/auth/login',
  logout: '/api/v1/auth/logout',
  userInfo: '/api/v1/users/me',
  recruitmentDashboard: '/api/v1/recruitment-dashboard',
  contackRanking: '/api/v1/contack-ranking',
  onboardCandidates: '/api/v1/onboard-candidates',
  rejectedCandidates: '/api/v1/rejected-candidates',
};

export default routes;
