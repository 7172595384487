/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TextInput = (props) => {
  const { type, className } = props;
  const cnTextInput = classNames('input', className);

  return (
    <>
      <input
        {...props}
        type={type}
        className={cnTextInput}
      />
    </>
  );
};

TextInput.defaultProps = {
  type: 'text',
  className: '',
};

TextInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

export default TextInput;
