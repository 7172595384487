/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export default (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14.674" height="8.751" viewBox="0 0 14.674 8.751">
    <g id="Group_26" dataname="Group 26" transform="translate(-818.086 -202.086)">
      <line id="Line_23" dataname="Line 23" x2="5.923" y2="5.923" transform="translate(819.5 203.5)" fill="none" stroke="#44b584" strokeLinecap="round" strokeWidth="2" />
      <line id="Line_24" dataname="Line 24" x1="5.923" y2="5.923" transform="translate(825.423 203.5)" fill="none" stroke="#44b584" strokeLinecap="round" strokeWidth="2" />
    </g>
  </svg>
);
