/* eslint-disable */
import React from 'react';

const DefaultItemRenderer = ({
  checked,
  option,
  onClick,
}) => (
  <div
    className="item-renderer"
  >
    <input
      type="checkbox"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
    />
    <span>{option.label}</span>
  </div>
);

export default DefaultItemRenderer;
