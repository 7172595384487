import React, {
  useState, useEffect, useCallback, useReducer,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import TermsAndConditions from '../TermsAndConditions';
import TextInput from '../shared/TextInput';
import PasswordInput from '../shared/PasswordInput';
import ContactUs from '../shared/ContactUs';
import Button from '../shared/Button';
import routes from '../../api/routes';

import logo from '../../images/contack-logo.png';

const SignUpCompany = () => {
  const location = useLocation();
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState('');
  const [signUpData, setSignUpData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      companyName: '',
      email: '',
      secret: '',
      password: '',
      passwordConfirm: '',
    },
  );

  const getSecretToken = useCallback(() => {
    const secretToken = queryString.parse(location.search).secret;
    setSignUpData({ secret: secretToken });
  }, [location]);

  const createCompanyRequest = async () => {
    try {
      const res = await axios.post(routes.signUpCompany, signUpData, { withCredentials: true });
      if (res.data.status === 200) history.push('/login');
    } catch (error) {
      setErrorMessages(error.response.data.errors[0].defaultMessage);
    }
  };

  useEffect(() => {
    getSecretToken();
  }, [getSecretToken]);

  const changeFieldHandler = (event) => {
    const { name, value } = event.target;
    setSignUpData({ [name]: value });
  };

  const signUpHandler = (event) => {
    event.preventDefault();
    if (signUpData.secret) {
      createCompanyRequest();
    }
  };

  return (
    <div className="d-flex u-h-100">
      <div className="row u-w-100">
        <div className="col-md-5 d-flex flex-column justify-content-center align-items-center u-bg-silver-tree">
          <h1 className="typography typography__heading1 typography--white">SIGN UP</h1>
          <p className="typography typography__body1 typography--white">
            to use all of Contack’s features:
          </p>
        </div>
        <div className="offset-md-1 col-md-5 offset-md-1 d-flex flex-column justify-content-around align-items-center">
          <form
            className="d-flex flex-column justify-content-center align-items-center col-xl-9"
            onSubmit={signUpHandler}
          >
            <div className="u-mt-2 u-mb-2 typography typography--persian-red">{errorMessages}</div>
            <TextInput
              type="text"
              className="u-mb-2"
              name="companyName"
              onChange={changeFieldHandler}
              value={signUpData.companyName}
              required
              placeholder="Company name"
            />
            <TextInput
              type="email"
              className="u-mb-2"
              name="email"
              onChange={changeFieldHandler}
              value={signUpData.email}
              required
              placeholder="Email"
            />
            <PasswordInput
              type="password"
              name="password"
              onChange={changeFieldHandler}
              value={signUpData.password}
              required
              placeholder="Password"
            />
            <PasswordInput
              type="password"
              name="passwordConfirm"
              onChange={changeFieldHandler}
              value={signUpData.passwordConfirm}
              required
              placeholder="Confirm password"
            />
            <div className="u-mb-4">
              <TermsAndConditions />
            </div>
            <Button className="u-mb-6" title="Sign up" />
          </form>
          <div className="d-flex flex-column justify-content-end align-items-center">
            <ContactUs />
            <img width="120" src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpCompany;
