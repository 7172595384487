/* eslint-disable import/prefer-default-export */

export const logout = () => localStorage.removeItem('isAuthrnticated');

export const isLogin = () => (!!localStorage.getItem('isAuthrnticated'));

export const formatContackELTV = (value) => {
  switch (true) {
    case (value > 0):
      return `$${parseFloat((value / 1000).toFixed(1))}k`;
    case (value < 0):
      return `-$${parseFloat((Math.abs(value) / 1000).toFixed(1))}k`;
    case (value === 0):
      return '0';
    default:
      return '-';
  }
};

export const formatContackELTVForGraph = (value) => {
  switch (true) {
    case (value > 0):
      return `$${parseFloat((value / 1000).toFixed(1))}k`;
    case (value < 0):
      return `-$${parseFloat((Math.abs(value) / 1000).toFixed(1))}k`;
    case (value === 0):
      return 'Current';
    default:
      return '-';
  }
};

export const sortAsc = (arr, param) => arr.sort((a, b) => (a[param] < b[param] ? -1 : 1));

export const sortDesc = (arr, param) => arr.sort((a, b) => (a[param] > b[param] ? -1 : 1));
