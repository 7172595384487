import React from 'react';
import RouterApp from './components/RouterApp';
import AppProvider from './AppProvider';
import AppLicantModalProvider from './ApplicantModalProvider';
import ApplicantModal from './components/ApplicantModal';

const App = () => (
  <AppProvider>
    <AppLicantModalProvider>
      <RouterApp />
      <ApplicantModal />
    </AppLicantModalProvider>
  </AppProvider>
);

export default App;
