/* eslint-disable */
import React from 'react';
import SelectPanel from '../select-panel';
import Dropdown from './Dropdown';

const MultiSelect = ({
  shouldToggleOnHover,
  className = 'multi-select',
  hasSelectAll = true,
  options,
  value,
  onChange,
  labelledBy,
  onMenuToggle,
}) => {
  const nvalue = value || [];
  return (
    <div className={`${className}`}>
      <Dropdown
        contentComponent={SelectPanel}
        shouldToggleOnHover={shouldToggleOnHover}
        contentProps={{
          options,
          hasSelectAll,
          value: nvalue,
          onChange,
        }}
        labelledBy={labelledBy}
        onMenuToggle={onMenuToggle}
      />
    </div>
  );
};

export default MultiSelect;
