const navList = [
  {
    navId: 0,
    label: 'Recruitment dashboard',
    iconCode: '&#9724;',
    path: '/recruitment-dashboard',
  },
  {
    navId: 1,
    label: 'Contack ranking',
    iconCode: '&#9679;',
    path: '/contack-ranking',
  },
  {
    navId: 2,
    label: 'Onboard candidates',
    iconCode: '&#9650;',
    path: '/onboard-candidates',
  },
  {
    navId: 3,
    label: 'Rejected candidates',
    iconCode: '&#215;',
    path: '/rejected-candidates',
  },
];

export default navList;
