import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import SignUpCompany from '../SignUpCompany';
import SignUpCompanyUser from '../SignUpCompanyUser';
import Login from '../Login';
import PublicRoute from './PublicRoute.component';
import PrivateRoute from './PrivateRoute.component';
import Sidebar from '../Sidebar';

import RecruitmentDasboardPage from '../RecruitmentDasboardPage';
import ContackRankingPage from '../ContackRankingPage';
import OnboardCandidatesPage from '../OnboardCandidatesPage';
import RejectedCandidatesPage from '../RejectedCandidatesPage';

const routes = [
  {
    path: '/recruitment-dashboard',
    exact: true,
    main: () => <RecruitmentDasboardPage />,
  },
  {
    path: '/contack-ranking',
    main: () => <ContackRankingPage />,
  },
  {
    path: '/onboard-candidates',
    main: () => <OnboardCandidatesPage />,
  },
  {
    path: '/rejected-candidates',
    main: () => <RejectedCandidatesPage />,
  },
];

const RouterApp = () => (
  <Router>
    <Switch>
      <PublicRoute restricted={false} path="/sign-up/company/user" exact component={SignUpCompanyUser} />
      <PublicRoute restricted={false} path="/sign-up/company" exact component={SignUpCompany} />
      <PublicRoute restricted={false} path="/login" exact component={Login} />
      <PublicRoute restricted={false} path="/" exact component={Login} />
      <>
        <div className="layout layout--gray">
          <Sidebar />
          {routes.map((route) => (
            <PrivateRoute
              key={uuidv4('91c1d97c-24f8-4e05-83f6-40ac73f2cb51')}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
          ))}
        </div>
      </>
    </Switch>
  </Router>
);

export default RouterApp;
