/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export default (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <title>Search</title>
    <g id="Group_184" data-name="Group 184" transform="translate(-536 -295)">
      <g id="Ellipse_10" dataname="Ellipse 10" transform="translate(536 295)" fill="none" stroke="#707070" strokeWidth="3">
        <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
        <circle cx="7.5" cy="7.5" r="6" fill="none" />
      </g>
      <line id="Line_30" dataname="Line 30" x2="5" y2="5" transform="translate(548.5 307.5)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="3" />
    </g>
  </svg>
);
