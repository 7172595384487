import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import UserInfo from '../UserInfo';
import Navigation from '../Navigation';
import routes from '../../api/routes';
import { logout } from '../../utils';

import { LogOut } from '../shared/svg';
import logo from '../../images/contack-logo-white.png';

const Sidebar = () => {
  const history = useHistory();

  const logoutRequest = async () => {
    try {
      const res = await axios.post(routes.logout);
      if (res.status === 200) {
        logout();
        history.push('/login');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div className="layout__l-side">
      <div className="app-left-sidebar justify-content-around">
        <UserInfo />
        <Navigation />
        <div className="d-flex justify-content-start align-items-center u-ml-5 u-mt-2 u-mb-1">
          <div className="logout" role="presentation" onClick={logoutRequest}>
            <LogOut />
          </div>
          <img className="navigation-logo" width="120" src={logo} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
