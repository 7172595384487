/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export default (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="27.982" height="17" viewBox="0 0 27.982 17">
    <g id="Group_177" dataname="Group 177" transform="translate(-101.983 -178.772)">
      <path id="Path_112" dataname="Path 112" d="M101.983,187.272h0c15.034,19.125,27.982,0,27.982,0h0C114.931,168.147,101.983,187.272,101.983,187.272Zm13.991,6.951a6.951,6.951,0,1,1,6.951-6.951A6.951,6.951,0,0,1,115.974,194.222Z" fill="#747474" />
      <path id="Path_113" dataname="Path 113" d="M258.242,254.954a3.287,3.287,0,1,1-3.287-3.287A3.287,3.287,0,0,1,258.242,254.954Z" transform="translate(-138.98 -67.683)" fill="#747474" />
    </g>
  </svg>
);
