/* eslint-disable */

import React, { useState, useEffect } from 'react';
import SelectList from './select-list';
import SelectItem from './select-item';

export const SelectPanel = (props) => {
  const {
    value,
    onChange,
    options,
    hasSelectAll,
    focusSearchOnOpen,
  } = props;
  const [focusIndex, setFocusIndex] = useState(
    focusSearchOnOpen ? -1 : 1,
  );

  const [selectAllLength, setSelectAllLength] = useState(0);

  const handleItemClicked = (index) => setFocusIndex(index);

  const updateFocus = (offset) => {
    let newFocus = focusIndex + offset;
    newFocus = Math.max(1, newFocus);
    newFocus = Math.min(newFocus, options.length);
    setFocusIndex(newFocus);
  };

  const handleKeyDown = (e) => {
    switch (e.which) {
      case 38: // Up Arrow
        if (e.altKey) {
          return;
        }
        updateFocus(-1);
        break;
      case 40: // Down Arrow
        if (e.altKey) {
          return;
        }
        updateFocus(1);
        break;
      default:
        return;
    }
    e.stopPropagation();
    e.preventDefault();
  };

  const selectAllOption = {
    label: 'Select all',
    value: '',
  };

  const selectAllValues = (checked) => {
    return options.filter(() => {
      if (checked) {
        return true;
      }
    });
  };

  const selectAllChanged = (checked) => {
    const newOptions = selectAllValues(checked);
    onChange(newOptions);
  };

  useEffect(() => {
    setSelectAllLength(selectAllValues(true).length);
    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if(value.length === 0) {
      selectAllChanged(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="select-panel" role="listbox" onKeyDown={handleKeyDown}>
      {hasSelectAll && (
        <SelectItem
          focused={focusIndex === 1}
          tabIndex={1}
          checked={selectAllLength === value.length}
          option={selectAllOption}
          onSelectionChanged={selectAllChanged}
          onClick={() => handleItemClicked(0)}
        />
      )}
      <SelectList
        {...props}
        focusIndex={focusIndex}
        onClick={(_e, index) => handleItemClicked(index)}
      />
    </div>
  );
};

export default SelectPanel;
