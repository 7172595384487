import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const AppLicantModalContext = createContext();

const AppLicantModalProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [applicantBaseId, setApplicantBaseId] = useState('');

  return (
    <AppLicantModalContext.Provider value={{
      openModal, setOpenModal, applicantBaseId, setApplicantBaseId,
    }}
    >
      {children}
    </AppLicantModalContext.Provider>
  );
};

AppLicantModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLicantModalProvider;
