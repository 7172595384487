/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import DefaultItemRenderer from './default-item';

const SelectItem = ({
  itemRenderer: ItemRenderer = DefaultItemRenderer,
  option,
  checked,
  focused,
  tabIndex,
  onSelectionChanged,
  onClick,
}) => {
  const itemRef = useRef();

  useEffect(() => {
    updateFocus();
  }, [focused]);

  const toggleChecked = () => {
    onSelectionChanged(!checked);
  };

  const handleClick = (e) => {
    toggleChecked();
    onClick(e);
  };

  const updateFocus = () => {
    if (focused && itemRef) {
      itemRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    switch (e.which) {
      case 13: // Enter
      case 32: // Space
        toggleChecked();
        break;
      default:
        return;
    }
    e.preventDefault();
  };

  return (
    <label
      className={`select-item ${checked && 'selected'}`}
      role="option"
      aria-selected={checked}
      tabIndex={tabIndex}
      ref={itemRef}
      onKeyDown={handleKeyDown}
    >
      <ItemRenderer
        option={option}
        checked={checked}
        onClick={handleClick}
      />
    </label>
  );
};

export default SelectItem;
