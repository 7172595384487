import React from 'react';

const ContactUs = () => (
  <div className="u-mb-5 typography typography--dim-gray">
    Please contact us at&nbsp;
    <a className="link typography typography--medium-sea-green" href="mailto:support@contack.io">
      support@contack.io
    </a>
    &nbsp;for help
  </div>
);

export default ContactUs;
